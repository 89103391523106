// Inserez ici votre banque de classes perso

.o-hide {
  overflow: hidden;
}
.shadow {
	box-shadow: 0px 5px 15px rgba($text-color, .7);
}
// Inserez ici votre banque de classes perso
body {
  // background: url(../images/charte/bg.jpg) top center no-repeat #fff fixed;
  // background-color: lighten($brand-second, 25%);
  // background-color: $body-bg;
  // background-color: #E1D282;
  // background: url(../images/homeBG.svg);
  // background-position: right top fixed;
  // background-repeat: no-repeat;
  // background-size: cover;
  }
.rowflex {
display: flex;
flex-flow: row wrap;
align-items: center;
justify-content: center;
}
.superside {
  font-family: 'Alegreya Sans SC', sans-serif;
  font-weight: 500;
  small {
    font-family: $font-family-base;
    text-transform: uppercase;
  display: block;
  padding-top: 5px;
  }
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  // font-style: italic;
  color: $brand-primary;
  margin: 2rem auto;
  // font-weight: 700;
  @extend .superside;
  small {
    // font-style: italic;
color: lighten($brand-second, 5%);
font-weight: 400;
}
}

.page-header, h1.page-header {
// @extend .container;
@extend .animated;
@extend .fadeIn;
@extend .text-center;
@extend .text-7xl;
animation-delay: .3s;
margin: 3rem auto !important;
// margin: .5rem auto 2rem auto !important;
// margin: -4rem auto 2rem auto !important;
padding: 2.5rem 0 !important;
// @extend .section-primary;
// border-bottom: solid .1rem $brand-primary;
// @extend .bloc-primary;
// background-color: $brand-primary;
// border-radius: .5rem;
// color: $body-bg;
// h1,p.h1 {
// @extend .animated;
// @extend .fadeInUp;
// animation-delay: .6s;
// margin: 20px auto;
// padding: 0 4vw;
small {
@extend .animated;
@extend .fadeInUp;
color: lighten($brand-second, 10%);
animation-delay: .9s;
// font-weight: 400;
// }
}
}


.bloc-contenu {
	// border-radius: 1rem !important;
	background-color: rgba($body-bg, .95);
  // background-image: url(../images/charte/002.jpg);
	// box-shadow: $box-shadow;
	box-shadow: 0px 5px 10px rgba($text-color, .25);
	overflow: hidden;
	position: relative;
	z-index:10;
	color: $text-color;
	// padding: 2.5rem;
	border-radius:  3rem 0 3rem 0 !important;
  margin: 1rem auto;
}
.bloc-round {
	max-height: 20rem;
	max-width: 20rem;
	font-size: 1.1rem;
	border-radius: 20rem !important;
	padding: 1.3rem !important;
	margin: 2rem !important;
}

// .boucher {
//   background: url(../images/charte/bg.gif) repeat-x top center,  url(../images/charte/bg.gif) repeat-x bottom center, $body-bg;
// }
// .boucher-primar {
//   background: url(../images/charte/bg.gif) repeat-x top center,  url(../images/charte/bg.gif) repeat-x bottom center, $brand-primary;
// }
section {
	@extend .clearfix;
  // min-height: 30vh;
	// padding: 20px 0!important;
}
.section-blanche {
	background-color: white;
  border-bottom: solid 3px $brand-second;
}
// .bg-white {
//   background: url(../images/charte/palmier.png) top right no-repeat #fff;
// }
.bg-primar {
  background: linear-gradient(25deg, lighten($brand-primary, 15%), darken($brand-primary, 5%))!important;;
}
.bg-second {
  background: linear-gradient(25deg, lighten($brand-second, 15%), darken($brand-second, 5%))!important;
}
// .section-primary {
//  @extend .bg-primar;

// 	color: $body-bg;
// 	h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
// 	color: $body-bg;
// 	}
// 	a {
// 		color: $brand-second;
// 	}
// }

// .section-second {
//   @extend .bg-second;
  // border-top: solid 3px $brand-primary;
//   border-bottom: solid 3px $brand-primary;

// 	a, small {
// 		color: $brand-primary;
// 	}
// }

// .section-noire {
// 	background-color: rgba($text-color, .85);
// 	background-color: rgba($gray-darker, .85);
// 	color: white;

// 	h1, h2, h3, h4, h5, h6,
// .h1, .h2, .h3, .h4, .h5, .h6, {
// 	color: white !important;
// }
// 	a, small {
// 		color: $brand-second !important;
// 	}
// }

.fond-image-section {
&::after{
content:'';
position: absolute;
top:0;
left:0;
right:0;
bottom:-50vh;
// background-color: rgba(lighten($brand-tierce, 5%), .45);
// background: linear-gradient(rgba($brand-second, .15), $brand-second);;
background: linear-gradient(rgba($body-bg, .4), rgba($body-bg, .8));;
z-index: -1;
}
}

.fond-image-section-fonce {
  &::after{
  content:'';
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:-50vh;
  // background-color: rgba(lighten($brand-tierce, 5%), .45);
  background: linear-gradient(rgba($brand-primary, .25), rgba($brand-primary, .9));;
  z-index: -1;
  }
  }

.goto-blanc {
  background: linear-gradient(rgba($body-bg, .0), rgba($body-bg, .9));;
  // margin-bottom: -.2rem;
  // max-height: 2rem;
  // min-width: 100%;
}
.outa-blanc {
  background: linear-gradient(rgba($body-bg, .9), rgba($body-bg, .0));;
  // margin-top: -2rem;
  // max-height: 2rem;
  // min-width: 100%;
}
.goto-noir {
  background: linear-gradient(rgba($text-color, .0), rgba($text-color, .5));;
  // margin-bottom: -.2rem;
  // max-height: 2rem;
  // min-width: 100%;
}
.outa-noir {
  background: linear-gradient(rgba($text-color, .5), rgba($text-color, .0));;
  // margin-top: -2rem;
  // max-height: 2rem;
  // min-width: 100%;
}
.goto-second {
  background: linear-gradient(rgba($brand-second, .75), rgba($brand-second, 1));;
  // margin-bottom: -.2rem;
  // max-height: 2rem;
  // min-width: 100%;
}
.outa-second {
  background: linear-gradient(rgba($brand-second, 1), rgba($brand-second, .75));;
  // margin-top: -2rem;
  // max-height: 2rem;
  // min-width: 100%;
}
.section-image {
@extend .fond-image-section;
min-height: 50vh;
// padding: 10vh 15%;
// border-bottom: solid .5rem $brand-second;
// margin: 40px 0;
// .container-max, .container, .conteneur, hr {
	position: relative;
	z-index: 10;
}

.section-image-fonce {
	position: relative;
	z-index: 10;
  @extend .fond-image-section-fonce;
}
.section-image-mini {
		min-height: 20vh;
		position: relative;
		z-index: 10;
	}

hr {
  // height: 60px;
  // background: url(../images/charte/hr.png) top center no-repeat;
  margin: 1rem auto;
}

// .section-image-01 {
	// @extend .fond-image-section;
	// background: url(../images/charte/000.jpg) bottom center fixed red;
	// background-size: cover;
	// overflow: hidden;
	// height: 45vh;
	// .container-max, .container, .conteneur {
		// position: relative;
		// z-index: 10;
	// }
// }

.img-intro {
	@extend .animated;
	@extend .fadeInup;
	@extend .img-responsive;
	@extend .center-block;
	border-top: solid 3px $brand-primary;

	border-bottom: solid 3px $brand-primary;
	animation-delay: .7s;
	margin: 0rem auto -5rem auto;
}
.delai-001 {
  // @extend .img-responsive;
  // @extend .center-block;
	@extend .animated;
	@extend .fadeInUp;
	animation-delay: .2s;
}
.delai-002 {
  // @extend .img-responsive;
  // @extend .center-block;
	@extend .animated;
	@extend .fadeInUp;
	animation-delay: .4s;
}
.delai-003 {
  // @extend .img-responsive;
  // @extend .center-block;
@extend .animated;
@extend .fadeInDown;
animation-delay: .6s;
}
.delai-004 {
@extend .animated;
@extend .fadeInDown;
animation-delay: .8s;
}
.diapo-bandeau {
	@extend .animated;
	@extend .fadeInLeft;
	animation-delay: .2s;
	border: solid 3px $brand-second;
	position :relative;
	// box-shadow: $box-shadow;
	img {
		@extend .img-responsive;
		max-height: 30rem !important;
	}
}
.texte-primary {
	color: $brand-primary !important;
}
.texte-second {
	color: $brand-second !important;
}
.texte-tierce {
	color: $brand-tierce !important;
}
.texte-noir {
	color: $text-color !important;
	// color: black !important;
}
.texte-blanc {
color: #fff !important;
text-shadow: 1px 3px 6px rgba($gray-darker, .15);
  small {
    color: lighten($brand-second, 25%) !important;
  }
}
.moins-fat {
	font-size: 1.5rem;
}
.texte-massif {
	font-size: 2.5rem;
}
.img-bulle {
@extend .img-circle;
@extend .img-responsive;
@extend .center-block;
@extend .text-center;
// height: 30px;
max-height: 12rem;
// border-radius: 100px;
// border: solid 2px $brand-primary;
border: solid .2rem lighten($brand-second, 15%);
// box-shadow: $box-shadow;
margin: 20px auto;
background-color: lighten($brand-second, 15%);
padding: 0;
position: relative;
z-index: 500;
transition: all ease-in .3s;
&:hover {
border: solid .2rem darken($brand-second, 5%);
}
}
.multi-pills {
@extend .list-inline;
>li {
border: solid 3px $brand-primary;
border-radius: 20px;
padding: 5px 1.6rem;
margin: 05px;
background-color: white !important;
>a {
padding: 50px !important;
color: #fff !important;
}
}
}

.margin-neg {
	margin-top: -5rem;
	// padding-top: 5rem;
  position: relative;
  z-index: 999;
}
.cinquieme {
margin: 20px auto;
padding: 20px 0;
@extend .list-inline;
// @extend .text-center;
@extend .center-block;

>li {
@extend .col-sm-6;
@media (min-width: $screen-md-min) {
width: 20%;
}
}
}

table {
	color: $text-color;
}

.goto-white {
  @extend .img-responsive;
  @extend .center-block;
  width: 100%;
  margin-bottom: -.1rem;
}
.outa-white {
  @extend .img-responsive;
  @extend .center-block;
  width: 100%;
  margin-top: -.1rem;
}
.bg-opablack {
  background-color: rgba($text-color, .5);
}

.bg-opawhite {
  background-color: rgba($body-bg, .75);
}

.bg-opaprimary {
  background-color: rgba($brand-primary, .75);
}
.bg-opasecond {
  background-color: rgba($brand-second, .75);
}
/* ==========================================================================
  popup-index
  ========================================================================== */




  .fiche-popup-index {
    position: relative;
    width: 100%;
    position: relative;
    z-index: 0;
    overflow: hidden;

    @media(min-width: $screen-md-min) {
      width: 30.3333%;
      margin: 1.5%;
      border-radius: .6rem;
    }
  }

  .popup-index_pict {
    margin: 20px 0px;
    width: 100%;
    height: 35rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .5rem;
    box-shadow: 3px 6px 12px 5px rgba($text-color, 0.25) ;

    @media(min-width: $screen-lg-min) {
      height: 35vh;
    }

    img {

      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .popup-index_caption {
    @extend .text-left;
    background-color: white;
    position: relative;
    z-index: 20;

    @media(min-width: $screen-lg-min) {
      position: absolute;
      bottom: 0;
      left: 35%;
      right: 0;
      background-color: transparent;

      &::after {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0;
        left: -15%;
        right: -20%;
        bottom: 0;
        background: white;
        transform: skew(-25deg);
        border-radius: .6rem 0 0 0;
      }
    }
  }

  .caption-inner {
    padding: 2rem 2rem 3rem;
    position: relative;
    z-index: 1;
  }

  .popup-index_titre {
    @extend .h4;
    margin: 0 0 1rem;

    small {
      font-family: $font-family-base;
      font-weight: 700;
      font-size: 1.2rem;
      padding-bottom: 1rem;
    }

    a {
      display: block;
    }
  }

  .popup-index_description {
    overflow: hidden;
    height: 4.2rem;
    // font-size: $font-size-small;
  }

  .popup-index_footer {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
  }

  .popup-index_btn {
    display: block;
    padding: 1rem 2rem;
    position: relative;
    z-index: 5;

    span {
      position: relative;
      z-index: 1;
      font-size: $font-size-small;
      color: white;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 0;
      top: 0;
      left: -5%;
      right: -20%;
      bottom: 0;
      background: linear-gradient(38deg, $brand-primary 0%, darken($brand-primary, 15%) 100%);
      transform: skew(-25deg);
      border-radius: .6rem 0 0 0;
    }
  }


  .embed-pdf{
    width: 100%;
    height : 70vh;
  }


  /* ==========================================================================
  popup-partenaire
  ========================================================================== */

.container-partenaires {
  @extend .center-block;
  @extend .clearfix;
  width: 100%;
  max-width: 90rem;
  border-radius: .6rem;
  // box-shadow: $box-shadow;
}

.popup-partenaire {
  @extend .fiche-base;
  width: 100%;
  margin: 0;
  background-color: white;
  overflow: hidden;
  border-bottom: solid 1px $gray-lighter;
  border-radius: 0;

  &:first-child {
    border-radius: .6rem .6rem 0 0;
  }

  &:last-child {
    border-radius: 0 0 .6rem .6rem;
  }

  @media(min-width: $screen-sm-min) {
    display: flex;
  }
}

.popup-partenaire_pict {
  width: 100%;
  padding: 5%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    @extend .img-responsive;
    @extend .center-block;
  }

  @media(min-width: $screen-sm-min) {
    width: 35%;
  }
}

.popup-partenaire_caption {
  width: 100%;
  padding: 5%;

  @media(min-width: $screen-sm-min) {
    width: 65%;
  }
}

.list-partenaire {
  @extend .list-unstyled;
  margin: 0;

  >li {
    padding: .5rem 0 .5rem 2.5rem;
    position: relative;
    border-top: solid 1px $gray-lighter;

    .fa {
      position: absolute;
      top: .7rem;
      left: 0;
      color: $brand-primary;
    }
  }
}

.underline {
    background-image: linear-gradient(#90a6eb,#90a6eb);
    background-repeat: no-repeat;
    background-size: 100% .6rem;
    background-position: 0 88%;
}

.bluebold1{
  font-weight: bold;
  color: #2e4fd1;
}

.bluebold2{
  font-weight: bold;
  color: #4c6ad7;
}

.superheight {
  min-height: 90vh !important;
}

.welcomebox {
  position: absolute;
  top : 25%;
  left : 0;
  width: 100%;
  height: 100%;

}
.list-chrono {
	margin: 2rem;
	@extend .list-unstyled;
	// font-size: $font-size-large;
	>li {
		>span {
			color: $brand-primary;
			display: block;
		}
		border-left: solid .2rem $brand-second;
		padding: 1.2rem 2rem;
		position: relative;
		&::after {
			content: '';
			position: absolute;
			top: 1rem;
			left: -1.1rem;
			width: 2rem;
			height: 2rem;
			background-color: $brand-primary;
			border-radius: 50%;
		}
	}
}
.list-chrono-inverse {
	@extend .list-chrono;
	>li {
		>a, span {
			color: lighten($brand-second, 5%);
      // color: white;
		}
		border-left: solid .2rem lighten($brand-tierce, 5%);
		&::after {
			content: '';
			background-color: lighten($brand-second, 5%);
		}
	}
}

.thumbnail {
  box-shadow: .2rem 1rem 2rem -2rem rgba($text-color, .5);
}

.list-tarifs {
  padding: .5rem;
  list-style: none;
   >li {
     @extend .md-flex;
     justify-content: space-between;
     padding: .2rem;
     border-bottom: solid .1rem rgba($brand-second, .5);
     >span {
      display: block;
      &:nth-child(1) {
        @extend .texte-primary;
        >i {
        @extend .texte-second;
        display: block;
        font-size: smaller;
        }
      }
      &:nth-child(2) {
        @extend .font-bold;
      }
    }
    }
}